<template>
  <div>
    <EditSupplier />
    <table class="table table-sm table-responsive-md">
      <thead class="dark">
        <tr>
          <th scope="col" class="text-center">Sr No.</th>
          <th scope="col" class="text-center">Name</th>
          <th scope="col" class="text-center">Email</th>
          <th scope="col" class="text-center">Phone</th>
          <th scope="col" class="text-center">City</th>
          <th scope="col" class="text-center">Country</th>
          <th scope="col" class="text-center">Address</th>
          <th scope="col" class="text-center">Status</th>
          <th scope="col" class="text-center">Action</th>
        </tr>
      </thead>

      <tbody v-if="showLoader == 1">
        <tr class="shimmer-td">
          <td colspan="9">
            <content-placeholders class="shimmer" :rounded="true">
              <content-placeholders-text :lines="10" />
            </content-placeholders>
          </td>
        </tr>
      </tbody>

      <tbody v-if="showLoader == 0 && displayedSuppliers.length > 0">
        <tr v-for="(supplier, index) in displayedSuppliers" :key="index">
          <td>
            <div class="company-link">
              <span>S-{{ index + 1 }}</span>
            </div>
          </td>
          <td v-if="supplier.picture == null">
            <div class="company-details">
              <div class="company-logo">
                <img src="../../assets/images/user-image.png" alt="" />
              </div>
              <h6 class="company-name">{{ supplier.supplier_name }}</h6>
            </div>
          </td>
          <td v-else>
            <div class="company-details">
              <div class="company-logo">
                <img :src="API_URL + supplier.picture" alt="" />
              </div>
              <h6 class="company-name">{{ supplier.supplier_name }}</h6>
            </div>
          </td>
          <td>
            <div class="company-link">
              <span>{{ supplier.email }}</span>
            </div>
          </td>
          <td>
            <div class="company-size">
              <span>{{ supplier.phone }}</span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span>{{ supplier.city }}</span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span>{{ supplier.country }}</span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span>{{ supplier.address }}</span>
            </div>
          </td>
          <td v-if="supplier.status == 1" class="text-center">
            <button class="active">Active</button>
          </td>
          <td v-else-if="supplier.status == 2" class="text-center">
            <button class="terminated">Not Active</button>
          </td>
          <td class="text-center">
            <div class="actions-btn">
              <i class="fa fa-pencil-alt" @click="callModal(supplier)"> </i>
              <i class="fa fa-trash" @click="deleteSupplier(supplier.id)"> </i>
            </div>
          </td>
        </tr>
      </tbody>

      <tbody v-if="showLoader == 0 && displayedSuppliers.length == 0">
        <tr class="no-data">
          <td colspan="9" class="shimmer">
            No Data Found.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import EditSupplier from "./supplierform/EditSupplier.vue";
export default {
  name: "SupplierTable",
  props: {
    displayedSuppliers: Array,
    showLoader: Number,
  },
  data() {
    return {
      API_URL: this.$supplierStorageURL,
    };
  },
  components: {
    EditSupplier,
  },

  methods: {
    callModal: function(supplier) {
      this.$root.$emit("openEditSupplier", supplier);
    },

    async deleteSupplier(id) {
      var formData = new FormData();
      formData.append("id", id);
      this.$swal({
        title: "Are you sure you want to <br /> delete Supplier ?",
        text: "You can’t undo this action.",
        icon: "error",
        confirmButtonText: "Delete",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          return axios
            .post(process.env.VUE_APP_API_URL + "supplier/delete", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.$root.$emit("removeSupplier", id);
                this.$swal("Deleted!", "Supplier has been deleted.", "success");
              } else {
                this.errorMessage = response.data.message;
              }
            });
        }
      });
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style></style>
