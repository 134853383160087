<template>
  <div class="top-wrapper">
    <h6>Suppliers Management</h6>
    <router-link class="custom-btn2" to="/add-supplier"
      >Create Supplier</router-link
    >
  </div>
</template>

<script>
export default {
  name: "SupplierTop",
};
</script>

<style></style>
